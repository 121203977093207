<template>
  <view-item title="配件入库">
    <template #operation>
      <!-- <en-dropdown @command="operation.add.command">
        <en-button type="primary">创建单据</en-button>
        <template #dropdown>
          <en-dropdown-item command="purchase" v-if="code === 'PUC'">采购订单</en-dropdown-item>
          <en-dropdown-item command="refund" v-if="code === 'PUCR'">采退退货</en-dropdown-item>
          <en-dropdown-item command="other" v-if="code === 'PUC'">其他进货</en-dropdown-item>
          <en-dropdown-item command="transfer" v-if="code === 'TRA'">移库入库</en-dropdown-item>
        </template>
      </en-dropdown> -->
      <en-button @click="operation.add.click" type="primary">创建单据</en-button>

      <button-ajax v-if="form.data.id" :disabled="form.disabled" type="primary" :method="operation.save.click">保存</button-ajax>

      <button-ajax v-if="form.data.id && code === 'PUC'" :disabled="form.disabled" type="primary" :method="operation.commit.click">
        保存并入库
      </button-ajax>

      <button-ajax v-if="form.data.id && code === 'OTH'" :disabled="form.disabled" type="primary" :method="operation.commit.click">
        保存并入库
      </button-ajax>

      <button-ajax v-if="form.data.id && code === 'PUCR'" :disabled="form.disabled" type="primary" :method="operation.commit.click">
        保存并退库
      </button-ajax>

      <button-ajax v-if="form.data.id && code === 'TRA'" :disabled="form.disabled" type="primary" :method="operation.commit.click">
        保存并移库
      </button-ajax>

      <en-button
        v-if=" (form.data.id &&code === 'PUC' && form.data.status?.code === 'A')||(form.data.id &&code === 'OTH' && form.data.status?.code === 'A')||(form.data.id &&code === 'PUCR' && form.data.status?.code === 'A')"
        type="primary"
        @click="operation.close.click"
      >
        结束订单
      </en-button>

      <button-ajax
        v-if="(form.data.id && code === 'PUC' && form.data.status?.code === 'A') || form.data.status?.code === 'S'"
        type="primary"
        :method="operation.cancel.click"
      >
        退单
      </button-ajax>

      <en-button v-if="form.data.id" @click="operation.print.click">打印</en-button>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button
          >操作 <en-icon><ArrowDown /></en-icon
        ></en-button>

        <template #dropdown>
          <en-dropdown-item :disabled="form.disabled" command="detail">
            {{ code === 'PUC' ? '编辑采购单' : code === 'PUCR' ? '编辑采退单' : code === 'OTH' ? '编辑其他进货单' : '编辑移库入库单' }}
          </en-dropdown-item>
          <!-- <en-dropdown-item :disabled="form.disabled" command="material" v-if="code === 'PUC'">一键领料</en-dropdown-item> -->
          <en-dropdown-item :disabled="!form.data.id" command="history" v-if="code === 'OTH' || code === 'TRA'">单据历史</en-dropdown-item>
          <en-dropdown-item command="export">导出</en-dropdown-item>
          <en-dropdown-item :disabled="form.disabled" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :code="manifest.code"
        :ajax="{ action: 'GET /enocloud/purchase/query', params: (params) => (params.payload = { typeCode: code }) }"
        :props="manifest.props"
        :routes="[
          { path: '/accessory/stock/in/purchase', name: '采购单' },
          { path: '/accessory/stock/in/refund', name: '采退单' },
          { path: '/accessory/stock/in/other', name: '其他进货单' },
          { path: '/accessory/stock/in/transfer', name: '移库入库单' }
        ]"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
        :ref="setRef('manifest')"
      >
        <template #STATUS="{ row }: { row: EnocloudInventoryDefinitions['PurchaseQueryDto'] }">
          <en-tag :type="row.status?.code === 'S' ? 'success' : row.status?.code === 'P' ? 'warning' : 'danger'">{{ row.status?.message }}</en-tag>
        </template>

        <template #FREIGHT_DOCUMENT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseQueryDto'] }">
          <en-button v-if="row.logisiticsImageUrlString" link type="primary" @click.stop="manifest.logisiticsImageUrlString.click(row)">
            查看
          </en-button>
        </template>

        <template #form="{ data }">
          <en-form-item label="单据搜索" v-if="code === 'PUC'">
            <en-input v-model="data.quickSearch" placeholder="采购单号/供应商/仓库/制单人"></en-input>
          </en-form-item>
          <en-form-item label="入货仓库" prop="warehouseId" v-if="code === 'OTH'">
            <select-maintain
              v-model="data.warehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
           
              
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="OE号">
            <en-input v-model="data.goodsOem"></en-input>
          </en-form-item>
          <en-form-item label="仓库" v-if="code === 'PUCR'">
            <en-input v-model="data.warehouseName"></en-input>
          </en-form-item>

          <en-form-item label="编码">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="配件">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="制单人" v-if="code === 'PUCR' || code == 'OTH'">
            <en-input v-model="data.preparedByName"></en-input>
          </en-form-item>

          <en-form-item label="经手人" v-if="code === 'PUCR' || code == 'OTH'" class="w-full">
            <select-maintain
              v-model="data.auditById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => {
                  params.payload = { name: value }
                }
              }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>

          <en-form-item label="发票类型">
            <select-maintain
              v-model="data.invoiceTypeName"
              :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['INVCTP']) }"
              :props="{ label: 'name', value: 'name' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="结算进度" v-if="code === 'PUC' || code == 'PUCR'">
            <select-maintain
              v-model="data.settlementProgressCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STLMTPRG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="结算方式" v-if="code === 'PUC' || code == 'PUCR'">
            <select-maintain
              v-model="data.settlingAccountMethodCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STLACMTD']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="计划到货" v-if="code === 'OTH'">
            <en-date-picker v-model="data.plannedArrivalDate" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item label="退库状态" v-if="code === 'PUCR'">
            <select-maintain
              v-model="data.stockInProgressCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STKPRG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="单据日期" v-if="code === 'PUC' || code == 'PUCR'">
            <en-date-picker
              v-model:start="data.startDate"
              v-model:end="data.endDate"
              type="daterange"
              value-format="YYYY-MM-DD"
              class="w-full"
            ></en-date-picker>
          </en-form-item>
          <en-form-item label="适用车型" v-if="code === 'PUC' || code == 'PUCR'">
            <en-input v-model="data.goodsVehicleSpec"></en-input>
          </en-form-item>
          <en-form-item label="备注" v-if="code === 'PUC' || code == 'OTH'">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务选择：{{ code === 'PUC' ? '采购' : code === 'PUCR' ? '采退' : code === 'OTH' ? '其他进货单' : '移库入库' }}</span>
          <span>供应商：{{ form.data.supplier?.name }}</span>
        </div>

        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
          
                <span>{{ form.data.warehouse?.name }}</span>
                <span>{{ form.data.type?.message }}类型</span>
                <span>{{ form.data.logisticsCompany?.name }}</span>
                <span>{{ form.data.freightPaymentMethod?.message }}</span>
                <span>{{ formatMoney(form.data.freight) }}</span>
                <span>{{ form.data.invoiceTypeName }}</span>
                <span>{{ formatPercent(form.data.taxRate) }}</span>
                <span>{{ form.data.preparedBy?.name }}</span>
                <span>{{ form.data.auditBy?.name }}</span>
                <en-tag :type="form.data.status?.code === 'S' ? 'success' : form.data.status?.code === 'P' ? 'warning' : 'danger'">{{
                  form.data.status?.message
                }}</en-tag>
                <span>{{ form.data.serialNo }}</span>
                <span>{{ formatDate(form.data.businessDate) }}</span>
              </div>
            </template>

            <en-form label-position="right" class="grid grid-cols-6">
              <en-form-item label="门店">{{ form.data.warehouse?.branch?.shortName }}</en-form-item>
            
              <!-- <en-form-item label="制单人">{{ form.data.auditBy?.name }}</en-form-item> -->
              <en-form-item label="批次号">{{ form.data.serialNo }}</en-form-item>
              <!-- <en-form-item label="经手人">{{ form.data.preparedBy?.name }}</en-form-item> -->
              <en-form-item label="计划到货">{{ formatDate(form.data.plannedArrivalDate) }}</en-form-item>
              <!-- <en-form-item label="物流单">
                <en-button type="primary" text :disabled="!form.data.imgUrls?.length" @click="form.imgUrls.click"> 查看 </en-button>
              </en-form-item> -->
              <en-form-item label="备注">{{ form.data.comment }}</en-form-item>
            </en-form>
          </en-collapse-item>
        </en-collapse>

        <div class="flex items-center gap-6 text-xs text-neutral-500">
          <div class="flex flex-col items-center gap-2">
            <span v-if="code === 'PUCR'">采退数量</span>
            <span v-else>总数量</span>
            <span>{{ formatNumber(form.summary?.count) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>含税贷款金额</span>
            <span>{{ formatMoney(form.summary?.amount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>不含税贷款金额</span>
            <span>{{ formatMoney(form.summary?.preTaxAmount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>含税总金额:</span>
            <span>{{ formatMoney(form.summary?.amount) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>不含税总金额</span>
            <span>{{ formatMoney(form.summary?.preTaxAmount) }}</span>
          </div>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.purchaseGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    :ajax="{
                      action: 'GET /enocloud/common/goods',
                      params: (params, value) => (params.payload = { name: value, statusCode: 'A' })
                    }"
                    :props="{ label: 'name', value: '' }"
                    value-key="id"
                    placeholder="请选择配件"
                    remote
                    :disabled="form.disabled"
                    @change="form.purchaseGoods.add.change"
                    class="w-110"
                  ></select-maintain>
                  <en-button type="primary" :disabled="form.disabled" @click="form.purchaseGoods.batch.click" v-if="code === 'PUC' || code === 'OTH'">
                    批量添加配件
                  </en-button>
                  <en-button type="primary" :disabled="form.disabled" @click="form.purchaseGoods.batch.click" v-if="code === 'PUCR'">
                    选择单据配件
                  </en-button>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic
                      :code="detailManifest.code"
                      :data="form.data.purchaseGoods"
                      :height="height"
                      show-selectable
                      :config="{
                        STOCKED_OUT_COUNT: { visible: false }
                      }"
                    >
                      <template #OPERATION="{ row, $index }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto']; $index: number }">
                        <en-button type="primary" link :disabled="form.disabled" @click="form.purchaseGoods.delete.click($index)">删除</en-button>
                        <en-button
                          type="primary"
                          link
                          :disabled="form.disabled"
                          @click="form.purchaseGoods.editPrice.click(row)"
                          v-if="code === 'PUC' || code === 'OTH'"
                          >编辑价格</en-button
                        >
                        <en-popover placement="top" trigger="click" width="auto" v-if="code === 'PUC'">
                          <price-panel :specification-id="row.goodsSpecification?.id"></price-panel>
                          <template #reference>
                            <en-button v-if="store.accessRightsHash.PRICE_PURCHASE && store.accessRightsHash.PRICE_SERVICE" type="primary" link>
                              上次进价/售价
                            </en-button>
                            <en-button v-if="store.accessRightsHash.PRICE_PURCHASE && !store.accessRightsHash.PRICE_SERVICE" type="primary" link>
                              上次进价
                            </en-button>
                            <en-button v-if="!store.accessRightsHash.PRICE_PURCHASE && store.accessRightsHash.PRICE_SERVICE" type="primary" link>
                              上次售价
                            </en-button>
                          </template>
                        </en-popover>
                      </template>

                      <template #NO="{ $index }: { $index: number }">{{ $index + 1 }}</template>

                      <template #OEM="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.goods?.oem }}
                      </template>
                      <template #BATCH_NO="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.inventory.batchNo }}
                      </template>
                      <template #WAREHOUSE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.goods.name }}
                      </template>

                      <template #NAME="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">{{ row.goods?.name }}</template>

                      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <en-input-number v-model="row.count" :min="0" :disabled="form.disabled" class="w-full"></en-input-number>
                      </template>

                      <template #SERIAL_NO="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <span v-if="code === 'OTH'"> {{ row.goods?.serialNo }}</span>
                        <en-button type="primary" link :disabled="form.disabled" v-else @click="form.purchaseGoods.edit.click(row)">
                          {{ row.goods?.serialNo }}
                        </en-button>
                      </template>

                      <template #AMOUNT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <en-input-number
                          :model-value="calculator.mul(row.count, row.price)"
                          :min="0"
                          :precision="6"
                          class="w-full"
                          :disabled="form.disabled"
                          @change="form.purchaseGoods.amount.change($event, row)"
                        ></en-input-number>
                        {{ calculator.mul(row.count, row.price) }}
                      </template>

                      <template #PLATE_NO="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <select-maintain
                          v-model="row.plateNo"
                          :ajax="{
                            action: 'GET /enocloud/common/vehicle',
                            params: (params) =>
                              (params.payload = {
                                rsexp: 'plateNo'
                              })
                          }"
                          :props="{ label: 'plateNo', value: 'plateNo' }"
                          value-key="id"
                          remote
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #PRICE_WITHOUT_TAX="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <en-input-number
                          v-model="row.preTaxPrice"
                          :min="0"
                          :precision="6"
                          :disabled="form.disabled"
                          class="w-full"
                          @change="form.purchaseGoods.preTaxPrice.change(row)"
                        ></en-input-number>
                        {{ row.preTaxPrice }}
                      </template>

                      <template #AMOUNT_WITHOUT_TAX="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <en-input-number
                          v-model="row.preTaxAmount"
                          :min="0"
                          :precision="6"
                          class="w-full"
                          :disabled="form.disabled"
                          @change="form.purchaseGoods.preTaxAmount.change(row)"
                        ></en-input-number>
                        {{ row.preTaxAmount }}
                      </template>

                      <template #PRICE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <select-maintain
                          v-model="row.price"
                          :ajax="{
                            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
                            params: (params) => (params.paths = [row.goodsSpecification?.id])
                          }"
                          :props="{ label: 'price',value: 'price' }"
                          allow-create
                          lazy
                          :disabled="!row.goodsSpecification?.id || form.disabled"
                          @change="form.purchaseGoods.price.change(row)"
                        >
                          <template #default="{ option }: { option: EnocloudCommonDefinitions['PriceDto'] }">
                            <div class="flex justify-between gap-6">
                              <span>{{ option.price }}</span>
                              <span class="text-neutral-500">{{ option.type?.message }}</span>
                            </div>
                          </template>
                        </select-maintain>
                        {{ row.price }}
                      </template>

                      <template #MAINTENANCE_PRICE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <en-input-number
                          v-model="row.servicePrice"
                          :min="0"
                          :precision="6"
                          :disabled="form.disabled"
                          class="w-full"
                        ></en-input-number>
                      </template>

                      <template #WAREHOUSE_SHELF="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <select-maintain
                          v-model="row.warehouseShelf"
                          :ajax="{
                            action: 'GET /enocloud/common/hint/:hintTypeStr',
                            params: (params) => (params.paths = ['WRHSSLF'])
                          }"
                          :props="{ label: 'name', value: 'name' }"
                          lazy
                          :disabled="form.disabled"
                        >
                        </select-maintain>
                        {{ row.warehouseShelf }}
                      </template>

                      <template #SHELF_LIFE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ `${row.goods?.shelfLifeMonths ?? '--'}月` }}
                      </template>

                      <template #GOODS_TYPE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.goods?.type?.message }}
                      </template>

                      <template #VEHICLE_SPEC="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.goods?.primaryVehicleSpec?.join(',') }}
                      </template>

                      <template #BRAND="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.goods?.brand }}
                      </template>

                      <template #PRODUCTION_PLACE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.goods?.placeOfProduction }}
                      </template>

                      <template #STOCKED_IN_COUNT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ row.stockedCount }}
                      </template>

                      <template #LEFT_COUNT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ calculator.sub(row.count ?? 0, row.stockedCount ?? 0) }}
                      </template>

                      <template #UNIT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <select-maintain
                          v-model="row.goodsSpecification"
                          :options="row.goods?.specifications"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          :disabled="form.disabled"
                          :clearable="false"
                        >
                        </select-maintain>
                      </template>

                      <template #SPECIFICATION="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        {{ `${row.goodsSpecification?.weight}${row.goods?.warehouseUnit}` }}
                      </template>

                      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                        <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <purchase-dialog v-model="dialog.visible" :code="code"></purchase-dialog>

  <purchase-detail v-model="detail.visible" :code="code" :data="form.data" @confirm="detail.success"></purchase-detail>

  <purchase-material v-model="material.visible"></purchase-material>

  <purchase-history v-model="history.visible" :data="form.data" :code="code"></purchase-history>

  <goods-selection v-model="select.visible" type="purchase" @confirm="select.confirm" :code="code"></goods-selection>

  <inventory-goods-select v-model="inventorySelect.visible" @confirm="inventorySelect.confirm"></inventory-goods-select>

  <goods-price v-model="price.visible" :data="price.data" :specification="price.specification"></goods-price>

  <en-dialog v-model="edit.visible" width="20%">
    <en-form :rules="edit.form.rules">
      <en-form-item label="配件名称：">
        <en-input v-model="edit.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="编 码：">
        <en-input v-model="edit.form.data.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="OE号:">
        <en-input v-model="edit.form.data.oem"></en-input>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'

import { GoodsPrice, GoodsSelection, InventoryGoodsSelect } from '@enocloud/business'
import { EnMessageBox, EnImage, EnMessage } from '@enocloud/components'
import { calculator } from '@enocloud/utils'
import { Purchase, print } from '@enocloud/print'
import PurchaseDialog from '@accessory/components/purchase-dialog.vue'
import PurchaseDetail from '@accessory/components/purchase-detail.vue'
import PurchaseMaterial from '@accessory/components/purchase-material.vue'
import PurchaseHistory from '@accessory/components/purchase-history.vue'
import { ArrowDown } from '@element-plus/icons-vue'

import type { GoodsSelectionOption } from '@enocloud/business'

const PricePanel = factory({
  props: {
    specificationId: Number
  },

  watch: {
    specificationId: {
      immediate: true,
      handler() {
        if (this.specificationId) this.table.get()
      }
    }
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
            data: 'array',
            loading: true,
            params(params) {
              params.paths = [this.specificationId]
            }
          }
        },
        computed: {
          purchasePrice() {
            return this.table.data.find((item) => item.type?.code === 'RPP')
          },
          salePrice() {
            return this.table.data.find((item) => item.type?.code === 'RMP')
          }
        }
      }
    }
  },

  render(h) {
    return h('div', { class: 'flex gap-2' }, [
      this.store.accessRightsHash.PRICE_PURCHASE &&
        h('div', { class: 'flex flex-col' }, [
          h('span', `进价：${this.table.purchasePrice?.price ?? '-'}`),
          h('span', `供应商：${this.table.purchasePrice?.customerName ?? '-'}`),
          h('span', `采购日期：${this.table.purchasePrice?.documentDate ?? '-'}`),
          h('span', `采购单号：${this.table.purchasePrice?.documentSerialNo ?? '-'}`)
        ]),
      this.store.accessRightsHash.PRICE_SERVICE &&
        h('div', { class: 'flex flex-col' }, [
          h('span', `售价：${this.table.salePrice?.price ?? '-'}`),
          h('span', `客户：${this.table.salePrice?.customerName ?? '-'}`),
          h('span', `销售日期：${this.table.salePrice?.documentDate ?? '-'}`),
          h('span', `工单单号：${this.table.salePrice?.documentSerialNo ?? '-'}`)
        ])
    ])
  }
})

export default factory({

  page: true,

  props: {
    code: {
      type: String as PropType<'PUC' | 'PUCR' | 'OTH' | 'TRA'>,
      default: 'PUC'
    }
  },

  components: {
    PurchaseDialog,
    PurchaseDetail,
    PurchaseMaterial,
    PurchaseHistory,
    GoodsPrice,
    GoodsSelection,
    PricePanel,
    InventoryGoodsSelect,
    ArrowDown
  },

  config: {
    children: {
      operation: {
        children: {
          add: {

            click() {
              switch (this.detail.code) {
                case 'PUC':
               
                  this.detail.visible = true
                  break
                case 'PUCR':
          
                  this.detail.visible = true
                  break
                case 'OTH':
             
                  this.detail.visible = true
                  break
                case 'TRA':
               
                  this.detail.visible = true
              }
            },
         
          },
          close: {
            async click() {
              await EnMessageBox.confirm('是否确认结束订单', '提示')
              await this.form.close()
              this.form.init()
              this.refs.manifest.table.method()
            }
          },
          save: {
            async click() {
              await this.form.update()
              return this.form.get()
            }
          },
          commit: {
            async click() {
              await this.form.update()

              const { purchaseGoods = [] } = this.form.data
              if (this.code === 'OTH') {
                if (!purchaseGoods.length) return EnMessage.warning('配件不可为空')
              }

              await this.form.audit({ addition: { id: this.form.data.id, additionalOperationCode: 'ADSI' } })
              this.refs.manifest.table.method()
              return this.form.get()
            }
          },
          cancel: {
            async click() {
              await this.form.update()
              this.refs.manifest.table.method()
              return this.form.cancel()
            }
          },
          over: {
            async click() {
              await this.form.update()
              return this.form.over()
            }
          },
          print: {
            click() {
              print(Purchase, { props: { data: this.form.data } })
            }
          },
          option: {
            async command(option: string) {
              switch (option) {
                case 'detail':
                  this.detail.visible = true
                  break
                case 'material':
                  this.material.visible = true
                  break
                case 'history':
                  this.history.visible = true
                  break
                case 'inject':
                  break
                case 'export':

                  window.open(`/enocloud/purchase/export/${this.form.data.id}`)

                  break
                case 'delete':
                  await EnMessageBox.confirm('是否确认删除单据', '提示')
                  await this.form.delete()
                  this.form.init()
                  this.refs.manifest.table.method()
              }
            }
          }
        }
      },
      detailManifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'PUC':
                return 'PUCDFLD'
              case 'PUCR':
                return 'PUCRDFLD'
              case 'OTH':
                return 'PUCODFLD'
              case 'TRA':
                return 'STFIDLFD'
            }
          }
        }
      },
      manifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'PUC':
                return 'PUCISEFD'
              case 'PUCR':
                return 'PUCRSEFD'
              case 'OTH':
                return 'PUCOSEFD'
              case 'TRA':
                return 'STFISEFD'
            }
          },
          props() {
            switch (this.code) {
              case 'OTH':
                return { start: 'startDate', end: 'endDate' }
              default:
                return { start: 'businessStartDate', end: 'businessEndDate' }
            }
          }
        },
        row: {
          click(row: EnocloudInventoryDefinitions['PurchaseQueryDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        },
        logisiticsImageUrlString: {
          click(row: EnocloudInventoryDefinitions['PurchaseQueryDto']) {
            EnMessageBox({
              title: '物流单照片',
              message: h(
                'div',
                { class: 'flex gap-4 flex-wrap' },
                row.logisiticsImageUrls.map((url) => {
                  return h(EnImage, { src: url, previewSrcList: row.logisiticsImageUrls, style: 'width: 100px; height: 100px' })
                })
              )
            })
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/:purchaseId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },

          export: {
            action: 'GET /enocloud/sale/export/:saleId',

            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          close: {
            action: 'POST /enocloud/purchase/:purchaseId/close',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/purchase',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          cancel: {
            action: 'POST /enocloud/purchase/:purchaseId/reverse',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },

          update: {
            action: 'PUT /enocloud/purchase',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/purchase/:purchaseId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },

          reverse: {
            action: 'POST /enocloud/purchase/:purchaseId/reverse',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enocloud/purchase/:purchaseId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          },
          summary() {
            return this.form.data.purchaseGoods?.reduce(
              (res, item) => {
                res.count = calculator.add(res.count, item.count)
                res.amount = calculator.add(res.amount, calculator.mul(item.count, item.price))
                res.preTaxAmount = calculator.add(res.preTaxAmount, item.preTaxAmount)
                return res
              },
              {
                count: 0,
                amount: 0,
                preTaxAmount: 0
              }
            )
          }
        },
        children: {
          imgUrls: {
            click() {
              EnMessageBox({
                title: '物流单照片',
                message: h(
                  'div',
                  { class: 'flex gap-4 flex-wrap' },
                  this.form.data.imgUrls.map((url) => {
                    return h('img', { src: url, width: 300, height: 300 })
                  })
                )
              })
            }
          },
          purchaseGoods: {
            edit: {
              click(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
                this.edit.form.data.id = row?.goods?.id
                this.edit.visible = true
                this.edit.form.get()
              }
            },
            add: {
              change(value: EnocloudCommonDefinitions['GoodsDto']) {
                this.form.data.purchaseGoods?.push({
                  goods: value,
                  goodsSpecification: value.specifications?.find((item) => item.defaultPurchase?.value) || value.specifications?.[0],
                  remainingInventoryCount: 0,
                  servicePrice: 0,
                  stockedOutCount: 0
                })
              }
            },
            batch: {
              click() {
                this.select.visible = true
              }
            },
            delete: {
              click(index: number) {
                this.form.data.purchaseGoods?.splice(index, 1)
              }
            },
            editPrice: {
              click(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
                this.price.data = row.goods!
                this.price.specification = row.goodsSpecification!
                this.price.visible = true
              }
            },
            price: {
              change(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
                row.preTaxPrice = calculator.div(row.price ?? 0, calculator.add(1, this.form.data.taxRate))
                row.preTaxAmount = calculator.mul(row.preTaxPrice, row.count)
              }
            },
            amount: {
              change(value: number, row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
                row.price = calculator.div(value, row.count ?? 0)
                this.form.purchaseGoods.price.change(row)
              }
            },
            preTaxPrice: {
              change(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
                row.price = calculator.mul(row.preTaxPrice, calculator.add(1, this.form.data.taxRate))
                this.form.purchaseGoods.price.change(row)
              }
            },
            preTaxAmount: {
              change(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
                row.price = calculator.div(calculator.mul(row.preTaxAmount, calculator.add(1, this.form.data.taxRate)), row.count ?? 0)
                this.form.purchaseGoods.price.change(row)
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      edit: {
        visible: false,
        children: {
          form: {
            data: {
              name: '',
              oem: '',
              serialNo: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/:goodsId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.edit.form.data.id]
                }
              },
              submit: {
                action: 'PUT /enocloud/common/goods',
                data: 'array',
                loading: true,
                params(params) {
                  params.payload = this.edit.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入配件名称' }]
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.edit.visible = false
          }
        },
        confirm: {
          async click() {
            if (this.edit.form.data.name === '') return EnMessage.warning('请填写配件名称')
            this.edit.form.submit().then(() => {
              this.form.get()
              this.edit.visible = false
            })
          }
        }
      },
      detail: {
        visible: false,
        code: 'PUC' as 'PUC' | 'PUCR' | 'OTH' | 'TRA',
        success(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      material: {
        visible: false
      },
      history: {
        visible: false
      },
      select: {
        visible: false,
        confirm(value: GoodsSelectionOption[]) {
          for (const item of value) {
            const exist = this.form.data.purchaseGoods?.find((p) => p.goods?.id === item.goods.id)

            if (exist) {
              exist.count = calculator.add(exist.count, item.count)
            } else {
              const init = {
                goods: item.goods,
                price:item.price,
                goodsSpecification: item.goodsSpecification,
                count: item.count,
                remainingInventoryCount: item.remainingInventoryCount,
                servicePrice: item.servicePrice,
                stockedOutCount: item.stockedOutCount
              }
              this.form.purchaseGoods.price.change(init)
              this.form.data.purchaseGoods?.push(init)
            }
          }
        }
      },
      price: {
        visible: false,
        data: null as EnocloudInventoryDefinitions['GoodsDto'] | null,
        specification: null as EnocloudInventoryDefinitions['GoodsSpecificationDto'] | null
      },
      inventorySelect: {
        visible: false,
        confirm(value: SelectGoodsDto[]) {
          for (const item of value) {
            const exist = this.form.data.purchaseGoods?.find((p) => p.goods?.id === item.goods.id)

            if (exist) {
              exist.count = calculator.add(exist.count, item.count)
            } else {
              const init = {
                goods: item.goods,
                goodsSpecification: item.goodsSpecification,
                count: item.count,
                remainingInventoryCount: 0,
                servicePrice: 0,
                stockedOutCount: 0
              }
              this.form.purchaseGoods.price.change(init)
              this.form.data.purchaseGoods?.push(init)
            }
          }
        }
      }
    }
  }
})
</script>
